import {
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    mergeClasses,
} from '@fluentui/react-components';
import {DateFilterDropdownV2Props, DateFilterDropdownValues} from './DateFilterDropdown.types';
import {useState} from 'react';
import CustomDateRangePickerDialog from './CustomDateRangePickerDialog/CustomDateRangePickerDialog';
import {DateRange} from '../UsageDashboard.types';
import {ButtonLabels} from '../UsageDashboard.constants';
import useClasses from './DateFilterDropdown.styles';
import {subDays} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {useViewportSize} from '@/components/ui/Grid';

export default function DateFilterDropdownV2(props: DateFilterDropdownV2Props) {
    const {isVisible} = props;
    const classes = useClasses();
    const {t} = useTranslation('admin');

    const {isSmallScreen, isMediumScreen} = useViewportSize();
    const shouldHideComponent = isSmallScreen || isMediumScreen;

    const [dropdownBtnTitle, setDropdownBtnTitle] = useState(t(props.selectedOptionText));
    const [showDatePickerDialog, setShowDatePickerDialog] = useState(false);
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: subDays(new Date(), 1),
        endDate: new Date(),
    });

    const handleFixedDateRangeBtnClick = (title: string) => {
        let startDate;
        let endDate = new Date();
        switch (title) {
            case DateFilterDropdownValues.Last7Days:
                startDate = subDays(endDate, 7);
                break;
            case DateFilterDropdownValues.Last3Days:
                startDate = subDays(endDate, 3);
                break;
            case DateFilterDropdownValues.LastMonth:
                startDate = subDays(endDate, 30);
                break;
            case DateFilterDropdownValues.Last24Hours:
            default:
                startDate = subDays(endDate, 1);
                break;
        }
        setDropdownBtnTitle(t(title));
        props.onOptionChanged({
            title,
            startDate: startDate,
            endDate: endDate,
        });
    };

    const handleCustomDateRangeBtnClick = (title: string) => {
        setShowDatePickerDialog(true);
        setDropdownBtnTitle(t(title));
    };

    const handleDatePickerDialogDismiss = () => {
        setShowDatePickerDialog(false);
    };

    const handleDatePickerDialogDateRangeChange = (range: DateRange) => {
        setShowDatePickerDialog(false);
        props.onOptionChanged({
            title: DateFilterDropdownValues.CustomDateRange,
            startDate: range.startDate,
            endDate: range.endDate,
        });
    };

    return (
        // The 'isVisible' prop is used to conditionally hide the component without resetting the state to default of Last24Hours
        <div
            data-testid="date-filter-dropdown"
            className={mergeClasses(classes.root, isVisible && classes.visible, props.className)}
            hidden={shouldHideComponent}
        >
            <Menu positioning="below-end">
                <MenuTrigger disableButtonEnhancement>
                    <MenuButton
                        data-testid="menu-button"
                        className={classes.dropdownTriggerBtn}
                        shape="circular"
                    >{`${t(ButtonLabels.DATE_DROPDOWN)}: ${dropdownBtnTitle}`}</MenuButton>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem
                            data-testid="item-last-24-hours"
                            onClick={() =>
                                handleFixedDateRangeBtnClick(DateFilterDropdownValues.Last24Hours)
                            }
                        >
                            {t(DateFilterDropdownValues.Last24Hours)}
                        </MenuItem>
                        <MenuItem
                            data-testid="item-last-3-days"
                            onClick={() =>
                                handleFixedDateRangeBtnClick(DateFilterDropdownValues.Last3Days)
                            }
                        >
                            {t(DateFilterDropdownValues.Last3Days)}
                        </MenuItem>
                        <MenuItem
                            data-testid="item-last-7-days"
                            onClick={() =>
                                handleFixedDateRangeBtnClick(DateFilterDropdownValues.Last7Days)
                            }
                        >
                            {t(DateFilterDropdownValues.Last7Days)}
                        </MenuItem>
                        <MenuItem
                            data-testid="item-last-month"
                            onClick={() =>
                                handleFixedDateRangeBtnClick(DateFilterDropdownValues.LastMonth)
                            }
                        >
                            {t(DateFilterDropdownValues.LastMonth)}
                        </MenuItem>
                        <MenuItem
                            data-testid="item-custom-date-range"
                            onClick={() =>
                                handleCustomDateRangeBtnClick(
                                    DateFilterDropdownValues.CustomDateRange,
                                )
                            }
                        >
                            {t(DateFilterDropdownValues.CustomDateRange)}
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
            <CustomDateRangePickerDialog
                isOpen={showDatePickerDialog}
                onDismiss={handleDatePickerDialogDismiss}
                dateRange={dateRange}
                onDateRangeChange={handleDatePickerDialogDateRangeChange}
                minSearchStartDate={props.minSearchStartDate}
            />
        </div>
    );
}
