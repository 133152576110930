import React, {useCallback, useMemo, useEffect} from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogTrigger,
    DialogSurface,
} from '@fluentui/react-components';
import {ReportFeedbackIcon} from '@/components/ui/icons';
import useBreadcrumbClasses from '@/components/Navigation/Breadcrumbs/Breadcrumbs.styles';
import {
    setSessionFeatureFlags,
    getSessionFeatureFlags,
    getFeatureFlagsFromUrl,
    replaceUrlFeatureFlagsAndReload,
    clearSessionFeatureFlags,
} from '@/util/hasStaticFeatureFlags';

{
    /**
     * A component that displays a button when url feature flags are enabled
     * and allows for viewing the feature flags as well as clearing them
     *
     * if a feature flag is set in the url, it will be stored in the session storage
     *
     * if an additional parameter is set in the url, the page will be reloaded to clear
     * the featureFlag params but preserve the other params
     */
}
function FeatureFlagIndicator() {
    const classes = useBreadcrumbClasses();

    const search = window?.location?.search;
    // get the feature flags from the url
    const urlParamFlags = useMemo<string[]>(() => {
        return getFeatureFlagsFromUrl();
    }, [search]);

    const scenario = useMemo(() => {
        // check if the search param has a scenario
        const urlParams = new URLSearchParams(search);
        return urlParams.get('scenario');
    }, [search]);

    // get the feature flags from the session storage
    // feature flags are stored here after they are picked up from the url params and the page is refreshed
    const sessionFlags = useMemo(() => getSessionFeatureFlags(), []);

    // if we have urlParamFlags we'll use these to override the current session flags
    useEffect(() => {
        if (urlParamFlags.length > 0) {
            // test that the flags that are being set from the url are not already set in the session
            setSessionFeatureFlags(urlParamFlags);
            replaceUrlFeatureFlagsAndReload();
        }
    }, [urlParamFlags]);

    const hasFeatureFlags = urlParamFlags.length > 0 || sessionFlags.length > 0;

    const displayFeatureFlags = useCallback(() => {
        return getSessionFeatureFlags().join(',');
    }, []);

    const handleClearFeatureFlags = useCallback(() => {
        clearSessionFeatureFlags();
        replaceUrlFeatureFlagsAndReload();
    }, []);

    return hasFeatureFlags ? (
        <Dialog>
            <DialogTrigger disableButtonEnhancement>
                <li role="menuitem" className={classes.crumb} key="featureFlags">
                    <Button
                        data-testid="feature-flags-indicator"
                        size="small"
                        appearance="primary"
                        icon={<ReportFeedbackIcon />}
                    />
                </li>
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Active Feature Flags</DialogTitle>
                    <DialogContent>
                        <div>{displayFeatureFlags()}</div>
                        {scenario && (
                            <div>
                                <p>MSW Scenario: {scenario}</p>
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button
                                data-testid="clear-cached-flags"
                                appearance="primary"
                                onClick={handleClearFeatureFlags}
                            >
                                Clear flags
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    ) : null;
}

export default FeatureFlagIndicator;
