import DataTable from './DataTable';
import {DimensionalHourlyAggregate} from '../UsageDashboard.types';
import {dimensionalAggregateTableColumns} from '../UsageDashboard.config';
import {Pagination} from '@/components/ui/Pagination/Pagination';
import {PaginationType} from '@/components/ui/Pagination/Pagination/Pagination.types';
import {
    ApiResponseDisplays,
    DEFAULT_PAGE_SIZE,
    UsageMonitoringFilterLabels,
} from '../UsageDashboard.constants';
import {Button, Spinner, Tooltip} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {USAGE_DATA_LOADING} from '../UsageDashboard.constants';
import useClasses from './DataTable.styles';
import {ApiResponseDisplay} from '../ApiResponseDisplay/ApiResponseDisplay';
import {SortingDirection} from '@/api/api';
import {AnimatePresence, motion} from 'framer-motion';
import useDataTableV2Transitions from './useDataTableV2Transitions';
import {useEffect, useRef} from 'react';
import {FilterIcon} from '@/components/ui/icons';

interface DataTableV2Props {
    data: DimensionalHourlyAggregate[];
    onPageNumberChange(pageNumber: number): void;
    totalDataCount: number;
    resetPagination: boolean;
    isTableLoading: boolean;
    sortDirection: SortingDirection;
    currentPage: number;
    shouldScrolltoTableOnDataRefresh: boolean;
    shouldFocusFilterButton: boolean;
    shouldShowFilterButton: boolean;
    onDateSortChanging: (sortDirection: SortingDirection) => Promise<void>;
    onFilterButtonClick: () => void;
}

export default function DataTableV2(props: DataTableV2Props) {
    const {t: tAdmin} = useTranslation('admin');
    const tableRef = useRef<HTMLDivElement>(null);
    const filterButtonRef = useRef<HTMLButtonElement>(null);

    const classes = useClasses();
    const {transitionVariant, dataGridVariant} = useDataTableV2Transitions();
    const isDimensionalDataEmpty = !props.isTableLoading && props.data.length == 0;

    useEffect(() => {
        if (
            props.shouldScrolltoTableOnDataRefresh &&
            !props.isTableLoading &&
            !isDimensionalDataEmpty
        ) {
            setTimeout(() => {
                if (tableRef.current) {
                    tableRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
                }
            }, 500);
        }
    }, [props.isTableLoading, isDimensionalDataEmpty, props.shouldScrolltoTableOnDataRefresh]);

    useEffect(() => {
        if (props.shouldFocusFilterButton) {
            if (filterButtonRef.current) {
                setTimeout(() => {
                    filterButtonRef?.current?.focus();
                }, 500);
            }
        }
    }, [props.shouldFocusFilterButton]);

    async function changeDateSort(e: any, setSortState: any, nextSortState: any) {
        try {
            await props.onDateSortChanging(nextSortState.sortDirection);
            setTimeout(() => {
                setSortState(nextSortState);
            }, 500);
        } catch (e) {
            console.log(e);
        }
    }

    function onTableSortChange(
        e: any,
        stateSetter: any,
        nextSortState: {sortColumn: string; sortDirection: SortingDirection},
    ) {
        if (Boolean(props?.onDateSortChanging)) {
            changeDateSort(e, stateSetter, nextSortState);
        }
    }
    return (
        <>
            <AnimatePresence mode="wait">
                {props.isTableLoading ? (
                    <motion.div
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={transitionVariant}
                        key="Spinner"
                        className={classes.motionDiv}
                    >
                        <Spinner
                            labelPosition="below"
                            aria-live="polite"
                            aria-label={tAdmin(USAGE_DATA_LOADING)}
                            size="medium"
                            label={`${tAdmin(USAGE_DATA_LOADING)}`}
                            className={classes.loadingSpinner}
                        />
                    </motion.div>
                ) : isDimensionalDataEmpty ? (
                    <motion.div
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={transitionVariant}
                        key="NoDataExperience"
                        className={classes.motionDiv}
                    >
                        {props.shouldShowFilterButton && (
                            <div className={classes.rightAlign}>
                                <Tooltip
                                    content={tAdmin(UsageMonitoringFilterLabels.Tooltip)}
                                    relationship="label"
                                >
                                    <Button
                                        data-testid="filter-open-button"
                                        appearance="transparent"
                                        icon={<FilterIcon />}
                                        onClick={props.onFilterButtonClick}
                                        ref={filterButtonRef}
                                    />
                                </Tooltip>
                            </div>
                        )}
                        <ApiResponseDisplay
                            title={tAdmin(ApiResponseDisplays.NO_DIMENSIONAL_DATA.title)}
                            subtitle={tAdmin(ApiResponseDisplays.NO_DIMENSIONAL_DATA.subtitle)}
                            isError={false}
                        />
                    </motion.div>
                ) : (
                    <>
                        <motion.div
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            variants={dataGridVariant}
                            key="UsageDataTable"
                            ref={tableRef}
                        >
                            <div className={classes.rightAlign}>
                                <Tooltip
                                    content={tAdmin(UsageMonitoringFilterLabels.Tooltip)}
                                    relationship="label"
                                >
                                    <Button
                                        data-testid="filter-open-button"
                                        appearance="transparent"
                                        icon={<FilterIcon />}
                                        onClick={props.onFilterButtonClick}
                                        ref={filterButtonRef}
                                    />
                                </Tooltip>
                            </div>
                            <DataTable<DimensionalHourlyAggregate>
                                data={props.data}
                                columns={dimensionalAggregateTableColumns}
                                onSortChange={onTableSortChange}
                                sortingParameters={{
                                    isSortable: true,
                                    sortDirection: props.sortDirection,
                                    sortColumn: [
                                        {
                                            column: 'date',
                                            isAscending: false,
                                            shouldDisableDefaultSorting: true,
                                        },
                                    ],
                                }}
                            />
                            <Pagination
                                mode={PaginationType.NumberDisplay}
                                onPageChange={(pageNumber: number) =>
                                    props.onPageNumberChange(pageNumber)
                                }
                                pageSize={DEFAULT_PAGE_SIZE}
                                recordCount={props.totalDataCount}
                                resetPagination={props.resetPagination}
                                disabled={props.isTableLoading}
                                hidden={props.isTableLoading}
                                overridenPageNumber={props.currentPage}
                            ></Pagination>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </>
    );
}
