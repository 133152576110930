// Aliases to feature flags populated based on the user's access.
// This is where you would add entries for ECS flags
export enum MedeinaUserFeatures {
    EnableVerboseLogging = 'EnableVerboseLogging',
    EnablePromptbookAutoParametrization = 'EnablePromptbookAutoParametrization',
    FidelisProvisioned = 'FidelisProvisioned!',
    DisableLocalization = 'DisableLocalization',
    DisableTimezone = 'DisableTimezone',
    Enable1pRbac = 'Enable1pRbac',
}

// Aliases to feature flags configured via URL parameters.
// This is not necessarily a comprehensive list.
export enum MedeinaUrlParamFeatures {
    Debug = 'debug',
    Fidelis = 'Fidelis',
    FidelisAfd = 'FidelisAfd',
    // shows the app language dropdown in Settings > Preferences
    ShowAppLanguage = 'ShowAppLanguage',
    // show the timezone dropdown in Settings > Preferences
    ShowTimeZone = 'ShowTimeZone',
    // common feature flag used to test accessibility features
    a11y = 'a11y',
    // common feature flag used to test accessibility features that are scoped to admins
    a11yAdmin = 'a11yAdmin',
    // allow setting the values for msft managed tenants
    OverrideSettings = 'OverrideSettings',
    // Enable workspace features
    WorkspacesEnabled = 'WorkspacesEnabled',
    // allow enabling of Enabling Usage Monitoring v2
    ViewUsageMonitoringv2 = 'ViewUsageMonitoringv2',
    // allow enabling of Truncate Usage Monitoring 2
    UsageMonitoringTruncate2 = 'UsageMonitoringTruncate2',
    // allow enabling of Truncate Usage Monitoring truncate 3
    UsageMonitoringTruncate1 = 'UsageMonitoringTruncate1',
    // allow enabling of Truncate Usage Monitoring truncate 4
    UsageMonitoringTruncate4 = 'UsageMonitoringTruncate4',
}

// Aliases to complex feature flags that are aggregated based on other feature flags and context.
export enum MedeinaMetaFeatures {
    // @/api/user/useFeatureLaunchExperience
    LaunchExperienceAFD = 'LaunchExperienceAFD',
    LaunchExperienceFidelisRBAC = 'LaunchExperienceFidelisRBAC',
    LaunchExperienceFidelisGA = 'LaunchExperienceFidelisGA',
    LaunchExperienceNonFidelisGA = 'LaunchExperienceNonFidelisGA',
}

export type MedeinaFeatureFlagValue = boolean | string;

export type MedeinaUserFeatureKeys = keyof typeof MedeinaUserFeatures;

export type MedeinaUrlParamFeatureKeys = keyof typeof MedeinaUrlParamFeatures;

export type MedeinaMetaFeatureKeys = keyof typeof MedeinaMetaFeatures;

export type MedeinaFeatureFlagReference =
    | MedeinaUserFeatureKeys
    | MedeinaUrlParamFeatureKeys
    | boolean
    | string;

interface MedeinaPublicFeatures {
    // GA launch stage: Whether we have migrated to AFD, and the `FidelisProvisioned!` flag is available.
    LaunchAfdProvision: boolean;
    // GA launch stage: Disable EAP FRE for all users.
    LaunchDisableEapFre: boolean;
    // GA launch stage: Role assignment feature enabled for migrated EAP customers.
    LaunchRBAC: boolean;
    // GA launch stage: All GA features released.
    LaunchGA: boolean;
    // Whether the "First Run Experience" is enabled.
    // TODO: This isn't currently integrated, either delete or integrate this.
    FirstRunExperience: boolean;
    // Whether telemetry is enabled.
    Telemetry: boolean;
    // Whether internal feedback links are shown in the menu.
    InternalFeedbackLinks: boolean;
    // Whether to override the normal API URI with a local development one.
    LocalApi: boolean;
    //Whether to enable cookie banner
    CookieBannerExperience: boolean;
    // Whether new search experience is enabled.
    NewSearchExperience: boolean;
    // Whether or not prompt suggestions are to be shown.
    PromptSuggestions: boolean;
    // Whether edit investigation name experience is enabled.
    EditInvestigationNameExperience: boolean;
    // Whether email to invited user investigation name experience is enabled.
    EmailToInvitedUser: boolean;
    // Whether or not the new skillset connector/admin OAuth UI flow is enabled.
    OAuthConnectorExperience: boolean;
    // Whether or not the plugins experience is enabled.
    PluginsExperience: boolean;
    //Whether to change the session UpdatedAt or not
    ChangeSessionUpdatedAt: boolean;
    //Whether or not the duplicate session is enabled.
    DuplicateSessionExperience: boolean;
    //Whether to enable promptbooks feature
    Promptbook: boolean;
    // Whether to enable the new home page
    NewHomePage: boolean;
    // Whether or not the new support central experience is enabled.
    HelpResourcesExperience: boolean;
    // Whether to enable new session page experience
    NewSessionPage: boolean;
    // Whether to show the announcement banner
    AnnouncementBanner: string | false;
    // Whether to allow non-admins to control plugins
    UserPluginManagementAllowed: boolean;
    // Whether to save pinboard summary
    SavePinboardSummary: boolean;
    // Whether to enable the support central widget and disable support link
    SupportCentral: boolean;
    // Whether to save session summary
    SaveSessionSummary: boolean;
    // Whether to enable new evaluation toolbar
    ShowNewEvaluationToolbar: boolean;
    // Whether to enable prototype routing preference
    PrototypeRoutingExperience: boolean;
    // Whether to enable Fidelis-based functionality.
    EnableFidelis: boolean;
    // Whether to enable file upload
    EnableFileUpload: boolean;
    // Whether to enable Compliance Based PluginManagement
    EnableComplianceBasedPluginManagement: boolean;
    // Whether to enable Compliance Based PluginManagement
    EnableO365ConsentStepInFRE: boolean;
    // Whether to enable the language and timezone dropdowns in Settings > Preferences
    EnableLocalization: boolean;
    // Whether to enable the time zone field
    EnableTimezone: boolean;
    // Whether to enable the geo selection during FRE
    EnableGeoSelection: boolean;
    // Whether to show the model switcher in the prompt action buttons
    EnableAOAIModelSwitcher: boolean;
    // Whether to enable the usage monitoring filter
    EnableUsageMonitoringV2: boolean;
    //Enable Monitoring V2 custom round up
    EnableUsageMonitoringCustomRoundup: boolean;

    //--- User feature flags.
    // Restricted Feature Flag to enable verbose logs.
    EnableVerboseLogging: MedeinaUserFeatures;
    // Feature Flag for enabling the promptbook auto parametrization
    EnablePromptbookAutoParametrization: MedeinaUserFeatures;
    // Whether the user/tenant/workspace is provisioned for Fidelis.
    // If falsey, the user will be delivered the EAP experience, rather than GA experience.
    FidelisProvisioned: MedeinaUserFeatures;
    // Feature Flag to disable localization
    DisableLocalization: MedeinaUserFeatures;
    // Feature Flag to disable custom timezone
    DisableTimezone: MedeinaUserFeatures;
    // Feature Flag to enable 1P RBAC
    Enable1pRbac: MedeinaUserFeatures;

    //--- URL param feature flags.
    // Flag for developers to hide debug console statements behind or enable other debug UI elements
    Debug: MedeinaUrlParamFeatures;
    // Swap all Medeina API calls to the Fidelis gateway endpoint.
    Fidelis: MedeinaUrlParamFeatures;
    // Swap all Medeina API calls to the AFD endpoint.
    FidelisAfd: MedeinaUrlParamFeatures;
    // Whether to show the AppLanguage field in Settings
    ShowAppLanguage: MedeinaUrlParamFeatures;
    // Whether to show the TimeZone field in Settings
    ShowTimeZone: MedeinaUrlParamFeatures;
    // Used by accessibility testing to enable feature testing
    a11y: MedeinaUrlParamFeatures;
    // Used by accessibility testing to enable feature testing scoped to admin users
    a11yAdmin: MedeinaUrlParamFeatures;
    // Used to override settings for msft managed tenants
    OverrideSettings: MedeinaUrlParamFeatures;
    // Enable workspace features
    WorkspacesEnabled: MedeinaUrlParamFeatures;
    // Enable Mock service worker
    EnableMSW: boolean;

    //--- Meta feature flags.
    // GA Launch stage 1: Medeina-Service AFD has been deployed as the primary API and `LaunchAfdProvision` = true.
    LaunchExperienceAFD: MedeinaMetaFeatures;
    // GA Launch stage 2: Enable role assignment for Fidelis-migrated EAP and GA customers.
    LaunchExperienceFidelisRBAC: MedeinaMetaFeatures;
    // GA Launch stage 3a: Enable GA features that require Fidelis (for GA and Fidelis-migrated EAP customers).
    LaunchExperienceFidelisGA: MedeinaMetaFeatures;
    // GA Launch stage 3b: Enable GA features that don't require Fidelis (for everyone, including Fidelis opt-out EAP customers).
    LaunchExperienceNonFidelisGA: MedeinaMetaFeatures;

    //Usage Monitoring V2 flag
    UsageMonitoringV2: boolean;

    // Mouse Tracking feature flag
    EnableMSClarity: boolean;

    /** Enable the error boundary to show the fallback component */
    EnableErrorBoundary: boolean;
    /** When an ErrorBoundary FallbackComponent renders allow an additional option to show full error details */
    EnableErrorBoundaryShowErrorDetails: boolean;
    /** Add additional logging for network requests */
    EnableEnhancedTelemetry: boolean;
    //** Feature flag to enable user interaction data */
    EnableUserInteractionData: boolean;
}

const MedeinaFeatures: MedeinaPublicFeatures = {
    LaunchAfdProvision: process.env['NEXT_PUBLIC_FEATURE_LAUNCH_AFD_PROVISION'] === 'true' ?? false,
    LaunchDisableEapFre:
        process.env['NEXT_PUBLIC_FEATURE_LAUNCH_DISABLE_EAP_FRE'] === 'true' ?? false,
    LaunchRBAC: process.env['NEXT_PUBLIC_FEATURE_LAUNCH_RBAC'] === 'true' ?? false,
    LaunchGA: process.env['NEXT_PUBLIC_FEATURE_LAUNCH_GA'] === 'true' ?? false,
    FirstRunExperience: process.env['NEXT_PUBLIC_FEATURE_FIRST_RUN_EXPERIENCE'] === 'true' ?? false,
    Telemetry: process.env['NEXT_PUBLIC_FEATURE_TELEMETRY'] === 'true' ?? false,
    InternalFeedbackLinks:
        process.env['NEXT_PUBLIC_FEATURE_INTERNAL_FEEDBACK_LINKS'] === 'true' ?? false,
    LocalApi: process.env['NEXT_PUBLIC_FEATURE_LOCAL_API'] === 'true' ?? false,
    CookieBannerExperience: process.env['NEXT_PUBLIC_FEATURE_COOKIE_BANNER'] === 'true' ?? false,
    NewSearchExperience:
        process.env['NEXT_PUBLIC_FEATURE_NEW_SEARCH_EXPERIENCE'] === 'true' ?? false,
    PromptSuggestions: process.env['NEXT_PUBLIC_FEATURE_PROMPT_SUGGESTIONS'] === 'true' ?? false,
    EditInvestigationNameExperience:
        process.env['NEXT_PUBLIC_FEATURE_EDIT_INVESTIGATION_NAME_EXPERIENCE'] === 'true' ?? false,
    EmailToInvitedUser:
        process.env['NEXT_PUBLIC_FEATURE_EMAIL_TO_INVITED_USER'] === 'true' ?? false,
    OAuthConnectorExperience:
        process.env['NEXT_PUBLIC_FEATURE_OAUTH_CONNECTOR_EXPERIENCE'] === 'true' ?? false,
    PluginsExperience: process.env['NEXT_PUBLIC_FEATURE_PLUGINS_EXPERIENCE'] === 'true' ?? false,
    ChangeSessionUpdatedAt:
        process.env['NEXT_PUBLIC_FEATURE_CHANGE_SESSION_UPDATEDAT'] === 'true' ?? false,
    DuplicateSessionExperience:
        process.env['NEXT_PUBLIC_FEATURE_DUPLICATE_SESSION'] === 'true' ?? false,
    Promptbook: process.env['NEXT_PUBLIC_FEATURE_PROMPT_BOOKS'] === 'true' ?? false,
    NewHomePage: process.env['NEXT_PUBLIC_FEATURE_NEW_HOME_PAGE'] === 'true' ?? false,
    HelpResourcesExperience:
        process.env['NEXT_PUBLIC_FEATURE_HELP_RESOURCES_EXPERIENCE'] === 'true' ?? false,
    NewSessionPage: process.env['NEXT_PUBLIC_FEATURE_NEW_SESSION_PAGE'] === 'true' ?? false,
    AnnouncementBanner:
        (process.env['NEXT_PUBLIC_FEATURE_SHOW_ANNOUNCEMENT_BANNER'] !== 'false' &&
            process.env['NEXT_PUBLIC_FEATURE_SHOW_ANNOUNCEMENT_BANNER']) ||
        false,
    UserPluginManagementAllowed:
        process.env['NEXT_PUBLIC_FEATURE_USER_PLUGIN_MANAGEMENT_ALLOWED'] === 'true' ?? false,
    SavePinboardSummary:
        process.env['NEXT_PUBLIC_FEATURE_ENABLE_PINBOARDSUMMARY_SAVE'] === 'true' ?? false,
    SupportCentral: process.env['NEXT_PUBLIC_FEATURE_SUPPORT_CENTRAL'] === 'true' ?? false,
    SaveSessionSummary:
        process.env['NEXT_PUBLIC_FEATURE_ENABLE_SESSIONSUMMARY_SAVE'] === 'true' ?? false,
    ShowNewEvaluationToolbar:
        process.env['NEXT_PUBLIC_FEATURE_NEW_EVALUATION_TOOLBAR'] === 'true' ?? false,
    PrototypeRoutingExperience:
        process.env['NEXT_PUBLIC_FEATURE_ENABLE_PROTOTYPE_ROUTING'] === 'true' ?? false,
    EnableFidelis: process.env['NEXT_PUBLIC_FEATURE_ENABLE_FIDELIS'] === 'true' ?? false,
    EnableFileUpload: process.env['NEXT_PUBLIC_FEATURE_ENABLE_FILE_UPLOAD'] === 'true' ?? false,
    EnableComplianceBasedPluginManagement:
        process.env['NEXT_PUBLIC_FEATURE_ENABLE_COMPLIANCE_BASED_PLUGIN_MANAGEMENT'] === 'true' ??
        false,
    EnableO365ConsentStepInFRE:
        process.env['NEXT_PUBLIC_FEATURE_ENABLE_O365_CONSENT_STEP_IN_FRE'] === 'true' ?? false,
    EnableLocalization: process.env['NEXT_PUBLIC_FEATURE_ENABLE_LOCALIZATION'] === 'true' ?? false,
    EnableTimezone: process.env['NEXT_PUBLIC_FEATURE_ENABLE_TIMEZONE'] === 'true' ?? false,
    EnableGeoSelection: process.env['NEXT_PUBLIC_FEATURE_ENABLE_GEO_SELECTION'] === 'true' ?? false,
    EnableAOAIModelSwitcher:
        process.env['NEXT_PUBLIC_FEATURE_ENABLE_AOAI_MODEL_SWITCHER'] === 'true' ?? false,
    EnableUsageMonitoringV2:
        process.env['NEXT_PUBLIC_FEATURE_ENABLE_USAGE_MONITORING_FILTER'] === 'true' ?? false,
    EnableUsageMonitoringCustomRoundup:
        process.env['NEXT_PUBLIC_FEATURE_ENABLE_USAGE_MONITORING_CUSTOM_ROUNDUP'] === 'true' ??
        false,

    // Mouse Tracking feature flag
    EnableMSClarity: process.env['NEXT_PUBLIC_FEATURE_ENABLE_MS_CLARITY'] === 'true' ?? false,

    //Usage Monitoring V2 flag
    UsageMonitoringV2: process.env['NEXT_PUBLIC_FEATURE_USAGE_MONITORING_V2'] === 'true' ?? false,
    // Enable Mock service worker
    EnableMSW: process.env['NEXT_PUBLIC_FEATURE_ENABLE_MSW'] === 'true' ?? false,

    // Error Boundary feature flags
    EnableErrorBoundary:
        process.env['NEXT_PUBLIC_FEATURE_ENABLE_ERRORBOUNDARY'] === 'true' ?? false,
    EnableErrorBoundaryShowErrorDetails:
        process.env['NEXT_PUBLIC_FEATURE_ENABLE_ERRORBOUNDARY_DETAILS'] === 'true' ?? false,

    // Add additional logging for network requests
    EnableEnhancedTelemetry:
        process.env['NEXT_PUBLIC_FEATURE_TELEMETRY_ENHANCED'] === 'true' ?? false,

    EnableUserInteractionData:
        process.env['NEXT_PUBLIC_FEATURE_USER_INTERACTION_DATA'] === 'true' ?? false,

    // User feature flags.
    EnableVerboseLogging: MedeinaUserFeatures.EnableVerboseLogging,
    EnablePromptbookAutoParametrization: MedeinaUserFeatures.EnablePromptbookAutoParametrization,
    FidelisProvisioned: MedeinaUserFeatures.FidelisProvisioned,
    DisableLocalization: MedeinaUserFeatures.DisableLocalization,
    DisableTimezone: MedeinaUserFeatures.DisableTimezone,
    Enable1pRbac: MedeinaUserFeatures.Enable1pRbac,

    // URL param feature flags.
    Debug: MedeinaUrlParamFeatures.Debug,
    Fidelis: MedeinaUrlParamFeatures.Fidelis,
    FidelisAfd: MedeinaUrlParamFeatures.FidelisAfd,
    ShowAppLanguage: MedeinaUrlParamFeatures.ShowAppLanguage,
    ShowTimeZone: MedeinaUrlParamFeatures.ShowTimeZone,
    a11y: MedeinaUrlParamFeatures.a11y,
    a11yAdmin: MedeinaUrlParamFeatures.a11yAdmin,
    OverrideSettings: MedeinaUrlParamFeatures.OverrideSettings,
    WorkspacesEnabled: MedeinaUrlParamFeatures.WorkspacesEnabled,

    // Meta feature flags.
    LaunchExperienceAFD: MedeinaMetaFeatures.LaunchExperienceAFD,
    LaunchExperienceFidelisRBAC: MedeinaMetaFeatures.LaunchExperienceFidelisRBAC,
    LaunchExperienceFidelisGA: MedeinaMetaFeatures.LaunchExperienceFidelisGA,
    LaunchExperienceNonFidelisGA: MedeinaMetaFeatures.LaunchExperienceNonFidelisGA,
};

export default MedeinaFeatures;
