import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        ...shorthands.margin(
            tokens.spacingVerticalXL,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalXL,
            tokens.spacingHorizontalNone,
        ),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        overflowX: 'auto',
    },
    grid: {
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
        backgroundColor: tokens.colorNeutralBackground1,
        width: 'max(100%, 1050px)',
    },
    gridRow: {
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
    },
    gridHeaderCell: {
        color: tokens.colorNeutralForeground4,
        fontWeight: tokens.fontWeightSemibold,
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
    },
    gridRowCell: {
        fontWeight: tokens.fontWeightRegular,
    },
    loadingSpinner: {
        marginTop: '10vh',
    },
    motionDiv: {
        height: '100%',
    },
    rightAlign: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
});
