import React, {useMemo, useState} from 'react';
import {
    createTableColumn,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    Dropdown,
    Option,
    TableColumnDefinition,
} from '@fluentui/react-components';
import useClasses from './PluginManagement.styles';
import {PluginManagementItem} from './PluginManagement.types';
import SkillRowMetadata from '@/components/ui/ConnectorModal/ConnectorModalContent/Skillsets/SkillRowMetadata';
import {PluginRbacSetting} from '@/components/Navigation/Preferences/Preferences.types';
import {
    PluginAvailabilityOptionIds,
    PluginAvailabilityOptions,
    PluginDataTableColumnSizingOptions,
} from './PluginManagement.constants';
import {useTranslation} from 'react-i18next';

interface PluginDataTableProps {
    pluginItems: PluginManagementItem[];
    onPluginItemChange: (item: PluginManagementItem) => void;
}

/**
 * Renders a table that displays plugin management information.
 *
 * @component
 * @example
 * ```tsx
 * <PluginDataTable
 *   pluginItems={pluginItems}
 *   onPluginItemChange={onPluginItemChange}
 * />
 * ```
 *
 * @param {Object} props - The component props.
 * @param {PluginManagementItem[]} props.pluginItems - The array of plugin items.
 * @param {(item: PluginManagementItem) => void} props.onPluginItemChange - The function to handle plugin item changes.
 * @returns {JSX.Element} The rendered PluginDataTable component.
 */
export default function PluginDataTable({pluginItems, onPluginItemChange}: PluginDataTableProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');

    const columns: TableColumnDefinition<PluginManagementItem>[] = useMemo(
        () => [
            createTableColumn<PluginManagementItem>({
                columnId: 'name',
                renderHeaderCell: () => {
                    return t('SettingsPluginAvailabilityColumnName');
                },
                renderCell: (item) => {
                    return <SkillRowMetadata skillsetDescriptor={item.descriptor} />;
                },
            }),
            createTableColumn<PluginManagementItem>({
                columnId: 'category',
                compare: (a, b) => {
                    return a.descriptor.category.localeCompare(b.descriptor.category);
                },
                renderHeaderCell: () => {
                    return t('SettingsPluginAvailabilityColumnCategory');
                },
                renderCell: (item) => {
                    return item.descriptor.category;
                },
            }),
            createTableColumn<PluginManagementItem>({
                columnId: 'available',
                compare: (a, b) => {
                    return a.allowed === b.allowed ? 0 : a.allowed ? -1 : 1;
                },
                renderHeaderCell: () => {
                    return t('SettingsPluginAvailabilityColumnAvailable');
                },
                renderCell: (item) => {
                    const dropdownTextValue = t(
                        PluginAvailabilityOptionIds[
                            item.allowed ? PluginRbacSetting.ANY : PluginRbacSetting.ADMIN
                        ],
                    );
                    return (
                        <Dropdown
                            className={classes.dropdown}
                            value={dropdownTextValue}
                            selectedOptions={
                                item.allowed ? [PluginRbacSetting.ANY] : [PluginRbacSetting.ADMIN]
                            }
                            onOptionSelect={(_, option) => {
                                const allowed = option.optionValue === PluginRbacSetting.ANY;
                                // Update the item with the new allowed value
                                onPluginItemChange({...item, allowed});
                            }}
                        >
                            {PluginAvailabilityOptions.map((option) => (
                                <Option
                                    key={option.value}
                                    value={option.value}
                                    text={t(option.text)}
                                >
                                    {t(option.text)}
                                </Option>
                            ))}
                        </Dropdown>
                    );
                },
            }),
        ],
        [classes, onPluginItemChange, t],
    );

    return (
        <div className={classes.root}>
            <DataGrid
                className={classes.grid}
                columns={columns}
                items={pluginItems}
                selectionAppearance={'none'}
                sortable={true}
                resizableColumns
                columnSizingOptions={PluginDataTableColumnSizingOptions}
            >
                <DataGridHeader>
                    <DataGridRow>
                        {({renderHeaderCell}) => (
                            <DataGridHeaderCell className={classes.gridHeaderCell}>
                                {renderHeaderCell()}
                            </DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody<PluginManagementItem>>
                    {({item, rowId}) => (
                        <DataGridRow<PluginManagementItem> className={classes.gridRow} key={rowId}>
                            {({renderCell, columnId}) => (
                                <DataGridCell className={classes.gridRow}>
                                    {renderCell(item)}
                                </DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>
        </div>
    );
}
