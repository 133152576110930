import {Reducer, useCallback, useMemo, useReducer, useState} from 'react';
import {DateRange, UsageDashboardState} from './UsageDashboard.types';
import {
    DEFAULT_DATA_ORDER,
    DEFAULT_PAGE_NUMBER,
    DEFAULT_PAGE_SIZE,
    DEFAULT_FILTER_STATE,
    DEFAULT_PRISTINE_SEARCH_FILTERS,
} from './UsageDashboard.constants';
import {SearchFacet} from '@/api/capacities/capacities.types';
import {DateFilterDropdownValues} from './DateFilterDropdown/DateFilterDropdown.types';
import {SortingDirection} from '@/api/api';
import {subDays} from 'date-fns';

interface useManageUsageDashboardStateResponse {
    getState: () => UsageDashboardState;
    updateTimePeriod: (startDate: Date, endDate: Date, dateText: string) => void;
    updatePageNumber: (pageNumber: number) => void;
    updateFilters: (searchFilters: SearchFacet[]) => void;
    updateSortDirection: (sortDirection: SortingDirection) => void;
    drillDownByDateRange: (startDate: Date, endDate: Date) => void;
    needsFacetRefresh: boolean;
    needsHourlyDataRefresh: boolean;
    needsDimensionalDataRefresh: boolean;
}

interface ManageUsageDashboardAction {
    type: string;
    payload: Partial<UsageDashboardState>;
}

const USAGE_DASHBOARD_DATA_ACTIONS = {
    DATE_RANGE_UPDATED: 'DATE_RANGE_UPDATED',
    PAGE_NUMBER_UPDATED: 'PAGE_NUMBER_UPDATED',
    FILTER_UPDATED: 'FILTER_UPDATED',
    SORT_DIRECTION_UPDATED: 'SORT_DIRECTION_UPDATED',
    GRAPH_BAR_CLICKED: 'GRAPH_BAR_CLICKED',
};

export default function useManageUsageDashboardState(): useManageUsageDashboardStateResponse {
    const DEFAULT_STATE: UsageDashboardState = useMemo(() => {
        const defaultDateRange: DateRange = {
            startDate: subDays(new Date(), 1),
            endDate: new Date(),
        };

        return {
            pageSize: DEFAULT_PAGE_SIZE,
            pageNumber: DEFAULT_PAGE_NUMBER,
            dateText: DateFilterDropdownValues.Last24Hours,
            searchFilters: DEFAULT_FILTER_STATE,
            startDate: defaultDateRange.startDate,
            endDate: defaultDateRange.endDate,
            needsFacetRefresh: true,
            needsHourlyDataRefresh: true,
            needsDimensionalDataRefresh: true,
            sortDirection: DEFAULT_DATA_ORDER,
            pristineSearchFilters: DEFAULT_PRISTINE_SEARCH_FILTERS,
        };
    }, []);

    const usageDashboardReducer = useCallback(
        (state: UsageDashboardState, action: ManageUsageDashboardAction): UsageDashboardState => {
            switch (action.type) {
                case USAGE_DASHBOARD_DATA_ACTIONS.DATE_RANGE_UPDATED:
                    return {
                        ...state,
                        startDate: action.payload.startDate as Date,
                        endDate: action.payload.endDate as Date,
                        dateText: action.payload.dateText as string,
                        searchFilters: DEFAULT_FILTER_STATE,
                        pageNumber: DEFAULT_PAGE_NUMBER,
                        needsFacetRefresh: true,
                        needsHourlyDataRefresh: true,
                        needsDimensionalDataRefresh: true,
                        sortDirection: DEFAULT_DATA_ORDER,
                        pristineSearchFilters: DEFAULT_PRISTINE_SEARCH_FILTERS,
                    };
                case USAGE_DASHBOARD_DATA_ACTIONS.FILTER_UPDATED:
                    return {
                        ...state,
                        searchFilters: action.payload.searchFilters as [],
                        needsFacetRefresh: false,
                        needsHourlyDataRefresh: false,
                        needsDimensionalDataRefresh: true,
                        sortDirection: DEFAULT_DATA_ORDER,
                        pristineSearchFilters: false,
                    };
                case USAGE_DASHBOARD_DATA_ACTIONS.PAGE_NUMBER_UPDATED:
                    return {
                        ...state,
                        pageNumber: action.payload.pageNumber as number,
                        needsFacetRefresh: false,
                        needsHourlyDataRefresh: false,
                        needsDimensionalDataRefresh: true,
                        pristineSearchFilters: false,
                    };
                case USAGE_DASHBOARD_DATA_ACTIONS.SORT_DIRECTION_UPDATED:
                    return {
                        ...state,
                        sortDirection: action.payload?.sortDirection ?? 'descending',
                        needsFacetRefresh: false,
                        needsHourlyDataRefresh: false,
                        needsDimensionalDataRefresh: true,
                        pristineSearchFilters: false,
                    };
                case USAGE_DASHBOARD_DATA_ACTIONS.GRAPH_BAR_CLICKED:
                    return {
                        ...state,
                        startDate: action.payload.startDate as Date,
                        endDate: action.payload.endDate as Date,
                        searchFilters: DEFAULT_FILTER_STATE,
                        pageNumber: DEFAULT_PAGE_NUMBER,
                        needsFacetRefresh: true,
                        needsHourlyDataRefresh: false,
                        needsDimensionalDataRefresh: true,
                        sortDirection: DEFAULT_DATA_ORDER,
                        pristineSearchFilters: DEFAULT_PRISTINE_SEARCH_FILTERS,
                    };
                default:
                    return state;
            }
        },
        [],
    );

    const [state, dispatch] = useReducer<Reducer<UsageDashboardState, ManageUsageDashboardAction>>(
        usageDashboardReducer,
        DEFAULT_STATE,
    );

    const updateTimePeriod = (startDate: Date, endDate: Date, dateText: string) => {
        dispatch({
            type: USAGE_DASHBOARD_DATA_ACTIONS.DATE_RANGE_UPDATED,
            payload: {startDate, endDate, dateText},
        });
    };

    const updatePageNumber = (pageNumber: number) => {
        dispatch({
            type: USAGE_DASHBOARD_DATA_ACTIONS.PAGE_NUMBER_UPDATED,
            payload: {pageNumber},
        });
    };

    const updateFilters = (searchFilters: SearchFacet[]) => {
        dispatch({
            type: USAGE_DASHBOARD_DATA_ACTIONS.FILTER_UPDATED,
            payload: {searchFilters},
        });
    };

    const updateSortDirection = (sortDirection: SortingDirection) => {
        dispatch({
            type: USAGE_DASHBOARD_DATA_ACTIONS.SORT_DIRECTION_UPDATED,
            payload: {sortDirection},
        });
    };

    const drillDownByDateRange = (startDate: Date, endDate: Date) => {
        dispatch({
            type: USAGE_DASHBOARD_DATA_ACTIONS.GRAPH_BAR_CLICKED,
            payload: {startDate, endDate},
        });
    };

    const getState = useCallback(() => state, [state]);
    const needsFacetRefresh = state.needsFacetRefresh;
    const needsHourlyDataRefresh = state.needsHourlyDataRefresh;
    const needsDimensionalDataRefresh = state.needsDimensionalDataRefresh;

    return {
        getState,
        updateTimePeriod,
        updatePageNumber,
        updateFilters,
        updateSortDirection,
        drillDownByDateRange,
        needsFacetRefresh,
        needsHourlyDataRefresh,
        needsDimensionalDataRefresh,
    };
}
