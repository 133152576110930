import {SearchCategory, UsageDashboardState} from './UsageDashboard.types';
import {
    useGetAggregatedCapacityUsageByDimensions,
    useGetUsageDataFacets,
    useGetAggregatedCapacityUsage,
    GetAggregatedCapacityUsageResponse,
    GetUsageDataFacetResponse,
    GetAggregatedCapacityUsageByDimensionsResponse,
} from '@/api/capacities';
import {useCallback, useEffect, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {GetAggregatedCapacityUsageParameters, SearchFacet} from '@/api/capacities/capacities.types';
import {DEFAULT_DATA_ORDER} from './UsageDashboard.constants';
import {fillBarGraphDataGaps} from './UsageDashboard.utils';
interface ManageUsageDashboardDataSourcesProps {
    capacityName: string;
    state: UsageDashboardState;
}

interface ManageUsageDashboardDataSourcesResponse {
    hourlyDataModel: GetAggregatedCapacityUsageResponse | undefined;
    dimensionalDataModel: GetAggregatedCapacityUsageByDimensionsResponse | undefined;
    facetDataModel: GetUsageDataFacetResponse | undefined;
    isDataFacetsError: boolean;
    isHourlyCapacityUsageError: boolean;
    isDimensionalDataError: boolean;
    isDimensionalDataLoading: boolean;
    isDimensionalDataRefetching: boolean;
    dataFacetsLoading: boolean;
    hourlyCapacityUsageLoading: boolean;
    hourlyCapacityUsageDataUpdatedAt: number | undefined;
    isAggregateDataFetched: boolean;
    areSearchFacetsAvailable: boolean;
}

export default function useManageUsageDashboardDataSources(
    props: ManageUsageDashboardDataSourcesProps,
): ManageUsageDashboardDataSourcesResponse {
    const [dimensionalData, setDimensionalData] =
        useState<GetAggregatedCapacityUsageByDimensionsResponse>();
    const [isAggregateDataFetched, setIsAggregateDataFetched] = useState(false);
    const [hourlyDataModel, setHourlyDataModel] = useState<GetAggregatedCapacityUsageResponse>();
    const [facetDataModel, setFacetDataModel] = useState<GetUsageDataFacetResponse>();
    const [areSearchFacetsAvailable, setAreSearchFacetsAvailable] = useState(false);

    const {
        data: hourlyCapacityUsageEventsData,
        isLoading: isHourlyCapacityUsageLoading,
        isFetched: isHourlyCapacityUsageFetched,
        isRefetching: isHourlyCapacityUsageRefetching,
        isError: isHourlyCapacityUsageError,
        dataUpdatedAt: hourlyCapacityUsageDataUpdatedAt,
        isSuccess: isHourlyCapacityUsageSuccess,
    } = useGetAggregatedCapacityUsage(
        {
            capacityResourceName: props.capacityName || '',
            startTime: props.state.startDate.toISOString(),
            endTime: props.state.endDate.toISOString(),
        },
        {enabled: !!props.capacityName && props.state.needsHourlyDataRefresh},
    );

    const {
        data: dataFacets,
        isError: isDataFacetsError,
        isFetched: isDataFacetsFetched,
        isLoading: isDataFacetsLoading,
        isRefetching: isDataFacetsRefetching,
        isSuccess: isDataFacetsSuccess,
    } = useGetUsageDataFacets(
        {
            resourceName: props.capacityName || '',
            startDate: props.state.startDate.toISOString(),
            endDate: props.state.endDate.toISOString(),
        },
        {enabled: !!props.capacityName && props.state.needsFacetRefresh},
    );

    const DEFAULT_BODY: GetAggregatedCapacityUsageParameters = {
        startDate: props.state.startDate.toISOString(),
        endDate: props.state.endDate.toISOString(),
        users: [],
        experiences: [],
        invocationTypes: [],
        plugins: [],
        categories: [],
        invocationCategories: [],
        pageSize: props.state.pageSize,
        pageNumber: props.state.pageNumber,
        sortingDirection: props.state.sortDirection,
    };

    const [body, setBody] = useState<GetAggregatedCapacityUsageParameters>(DEFAULT_BODY);

    const {
        data: aggregatedCapacityUsageByDimensionsData,
        isSuccess: isDimensionalDataSuccess,
        isLoading: isDimensionalDataLoading,
        isRefetching: isDimensionalDataRefetching,
        isError: isDimensionalDataError,
    } = useGetAggregatedCapacityUsageByDimensions(
        {
            resourceName: props.capacityName || '',
            body,
        },
        {enabled: !!props.capacityName && props.state.needsDimensionalDataRefresh},
    );

    const queryClient = useQueryClient();

    const dataFacetsLoading = !isDataFacetsFetched || isDataFacetsRefetching || isDataFacetsLoading;
    const hourlyCapacityUsageLoading =
        !isHourlyCapacityUsageFetched || isHourlyCapacityUsageLoading;

    const populateFacets = useCallback((searchFacets: SearchFacet[]) => {
        body.users = [];
        body.experiences = [];
        body.invocationTypes = [];
        body.plugins = [];
        body.invocationCategories = [];

        searchFacets?.forEach((facet) => {
            switch (facet.category) {
                case SearchCategory.CopilotExperience:
                    body.experiences = facet.values.map((value) => value.toString());
                    break;
                case SearchCategory.InvocationCategory:
                    body.invocationCategories = facet.values.map((value) => value.toString());
                    break;
                case SearchCategory.InvocationType:
                    body.invocationTypes = facet.values.map((value) => value.toString());
                    break;
                case SearchCategory.PluginUsed:
                    body.plugins = facet.values.map((value) => value.toString());
                    break;
                case SearchCategory.Users:
                    body.users = facet.values.map((value) => value.toString());
                default:
                    break;
            }
        });
    }, []);

    // This ensure that sorting direction gets updated
    // even if there is only a row worth of data
    useEffect(() => {
        const hasFetchingCompleted = !isDimensionalDataLoading && !isDimensionalDataRefetching;
        if (!isDimensionalDataLoading && !isDimensionalDataRefetching) {
            setIsAggregateDataFetched(true);
        }

        if (hasFetchingCompleted && isDimensionalDataSuccess) {
            setDimensionalData(aggregatedCapacityUsageByDimensionsData);
        } else {
            setDimensionalData(undefined);
        }
    }, [isDimensionalDataLoading, isDimensionalDataRefetching, isDimensionalDataSuccess]);

    useEffect(() => {
        const hasHourlyDataFetchingCompleted =
            !isHourlyCapacityUsageLoading && !isHourlyCapacityUsageRefetching;
        if (hasHourlyDataFetchingCompleted && isHourlyCapacityUsageSuccess) {
            hourlyCapacityUsageEventsData.value = fillBarGraphDataGaps(
                hourlyCapacityUsageEventsData?.value,
                props.state.startDate,
                props.state.endDate,
            );
            setHourlyDataModel(hourlyCapacityUsageEventsData);
        }
    }, [
        isHourlyCapacityUsageLoading,
        isHourlyCapacityUsageRefetching,
        isHourlyCapacityUsageSuccess,
    ]);

    useEffect(() => {
        const hasFetchingCompleted = !isDataFacetsLoading && !isDataFacetsRefetching;
        if (hasFetchingCompleted && isDataFacetsSuccess) {
            setFacetDataModel(dataFacets);
        }
    }, [isDataFacetsLoading, isDataFacetsRefetching, isDataFacetsSuccess]);

    useEffect(() => {
        const searchFacets = props.state.searchFilters;
        populateFacets(searchFacets);
        body.startDate = props.state.startDate.toISOString();
        body.endDate = props.state.endDate.toISOString();
        body.pageSize = props.state.pageSize;
        body.pageNumber = props.state.pageNumber;
        body.sortingDirection = props?.state?.sortDirection ?? DEFAULT_DATA_ORDER;
        setBody(body);
    }, [props.state]);

    useEffect(() => {
        var areFacetsAvailable = false;

        if ((dataFacets?.value?.length ?? 0) !== 0) {
            for (var i = 0; i < (dataFacets?.value?.length ?? 0); i++) {
                areFacetsAvailable = (dataFacets?.value[i].values?.length ?? 0) > 0;
                if (areFacetsAvailable) {
                    areFacetsAvailable = true;
                    break;
                }
            }
        }
        setAreSearchFacetsAvailable(areFacetsAvailable);
    }, [dataFacets]);

    useEffect(() => {
        if (props.state.needsFacetRefresh) {
            queryClient.refetchQueries(
                [
                    'usage-facets',
                    props.capacityName,
                    props.state.startDate.toISOString(),
                    props.state.endDate.toISOString(),
                ],
                {exact: true},
            );
        }
        if (props.state.needsHourlyDataRefresh) {
            queryClient.refetchQueries(
                [
                    'capacities-usage',
                    props.capacityName,
                    props.state.startDate.toISOString(),
                    props.state.endDate.toISOString(),
                ],
                {exact: true},
            );
        }
        if (props.state.needsDimensionalDataRefresh) {
            setIsAggregateDataFetched(false);
            queryClient.refetchQueries(['dimensions', props.capacityName], {exact: true});
        }
    }, [
        props.state.searchFilters,
        props.state.startDate,
        props.state.endDate,
        props.state.pageNumber,
        props.state.sortDirection,
    ]);

    return {
        hourlyDataModel: hourlyDataModel,
        dimensionalDataModel: dimensionalData,
        facetDataModel: facetDataModel,
        isDataFacetsError,
        isHourlyCapacityUsageError,
        isDimensionalDataError,
        dataFacetsLoading,
        isDimensionalDataLoading,
        isDimensionalDataRefetching,
        hourlyCapacityUsageLoading,
        hourlyCapacityUsageDataUpdatedAt,
        isAggregateDataFetched,
        areSearchFacetsAvailable,
    };
}
