import React, {useState, useEffect} from 'react';
import {Label, Dropdown, Option, useId} from '@fluentui/react-components';
import usePreferenceClasses from '../../Navigation/Preferences.styles';
import {useTranslation} from 'react-i18next';
import {useUpdateUserPreferences} from '@/api/user';
import {Workspace, useGetWorkspaces} from '@/api/workspaces';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';

function DefaultWorkspaceDropdown() {
    const preferenceClasses = usePreferenceClasses();
    const {t} = useTranslation('common');
    const updateUserPreferences = useUpdateUserPreferences();
    const defaultWorkspaceId = useId('defaultWorkspace');
    // default workspace data
    const {workspaceName, ...rest} = useWorkspaceState();
    const {
        data: workspacesData,
        isFetched: isWorkspacesDataFetched,
        isError: isWorkspacesDataError,
        isLoading: isWorkspacesLoading,
        error: workspacesError,
    } = useGetWorkspaces();
    const [selectedWorkspace, setSelectedWorkspace] = useState(workspaceName);
    useEffect(() => {
        setSelectedWorkspace(workspaceName);
    }, [workspaceName]);
    // change workspace
    const handleMenuItemClick = (workspace: Workspace) => {
        setSelectedWorkspace(workspace.name);
    };

    return (
        <div className={preferenceClasses.field}>
            <h3>{t('SettingsDefaultWorkspaceHeading')}</h3>
            <Label id={defaultWorkspaceId} className={preferenceClasses.label}>
                {t('SettingsDefaultWorkspaceDescription')}
            </Label>
            <div className={preferenceClasses.dropdownWrapper}>
                <Dropdown
                    placeholder={t('SettingsDefaultWorkspaceDropdownPlaceholder')}
                    value={selectedWorkspace}
                >
                    {workspacesData?.value?.map((workspace: Workspace, index: number) => (
                        <Option key={index} onClick={() => handleMenuItemClick(workspace)}>
                            {workspace.name}
                        </Option>
                    ))}
                </Dropdown>
            </div>
        </div>
    );
}

export default DefaultWorkspaceDropdown;
