import React, {useState, useEffect, useRef, useCallback} from 'react';
import {setupWorker, SetupWorker} from 'msw';
import {MedeinaScenario} from '../MedeinaMSWScenarios';

import MedeinaFeatures from '@/util/features';
import {getStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';

/** Wrapper component that enables MSW  */
const MswInitializer = ({children}: any) => {
    const enableMSW = getStaticFeatureFlag(MedeinaFeatures.EnableMSW);
    // mswScenario will be passed in from the URL query string scenario parameter
    const urlScenario = document?.location.search.split('scenario=')[1];
    // ensure that the passed in scenario is a valid scenario from our list
    const scenario = Object.values(MedeinaScenario).includes(urlScenario) ? urlScenario : '';

    // If the feature is not turned on or there's no matching scenario, signal to render the children immediately
    const loadMsWScenario = Boolean(enableMSW && scenario);
    // If we don't need to load the MSW scenario, render the children immediately
    const [shouldRenderChildren, setShouldRenderChildren] = useState(!loadMsWScenario);
    const worker = useRef<SetupWorker | undefined>(undefined);

    useEffect(() => {
        // If the feature is not turned on return immediately
        // if there's no matching scenario we also return
        if (!enableMSW || !scenario) return;
        const setupMocks = async () => {
            try {
                // dynamically import the scenario handlers
                // this is done to ensure that the handlers are only loaded when needed
                const scenarioHandlers = await import(`../scenarioHandlers/${scenario}`);
                if (scenarioHandlers) {
                    worker.current = setupWorker(...scenarioHandlers.default);
                    await worker.current.start({
                        onUnhandledRequest: 'bypass',
                    });
                    console.log('MSW worker started');
                    setShouldRenderChildren(true);
                }
            } catch (error) {
                console.error('Failed to start MSW worker:', error);
            }
        };

        if (enableMSW && worker.current === undefined) {
            setupMocks();
        }
        // when we unmount, stop the worker
        return () => {
            if (enableMSW && worker.current && scenario) {
                worker.current.stop();
            }
        };
    }, [enableMSW, scenario]);

    return shouldRenderChildren ? children : null;
};

export default MswInitializer;
