import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import {DismissIcon} from './ui/icons';
import {useTranslation} from 'react-i18next';
import useClasses from './SetDefaultWorkspaceDialog.styles';
import UserSettingsModal from '@/components/Navigation/UserSettingsModal';

interface SetDefaultWorkspaceDialogProps {
    isSetDefaultWorkspaceDialogOpen: boolean;
    onClose: () => void;
}

export default function SetDefaultWorkspaceDialog(props: SetDefaultWorkspaceDialogProps) {
    const [isSetDefaultWorkspaceDialogOpen, setSetDefaultWorkspaceDialogOpen] =
        useState<boolean>(false);
    const {t} = useTranslation('common');
    const classes = useClasses();

    const closeDefaultWorkspaceDialogConfirmation = () => {
        props.onClose();
    };

    // settings modal
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const restoreFocusTargetAttribute = useRestoreFocusTarget();
    const restoreFocusSourceAttribute = useRestoreFocusSource();

    useEffect(() => {
        setSetDefaultWorkspaceDialogOpen(props.isSetDefaultWorkspaceDialogOpen);
    }, [props.isSetDefaultWorkspaceDialogOpen]);

    return (
        <>
            <Dialog open={isSetDefaultWorkspaceDialogOpen}>
                <DialogSurface>
                    <DialogTrigger disableButtonEnhancement>
                        <Button
                            className={classes.closeButton}
                            data-test-id="set-default-workspace-dialog-close-button"
                            appearance="transparent"
                            aria-label="Set default workspace dialog"
                            onClick={closeDefaultWorkspaceDialogConfirmation}
                        >
                            <DismissIcon className={classes.closeIcon} />
                        </Button>
                    </DialogTrigger>
                    <DialogBody>
                        <DialogTitle className={classes.titleSection}>
                            <div className={classes.titleContainer}>
                                <div>{t('ChooseNewDefaultWorkspaceTitle')}</div>
                            </div>
                        </DialogTitle>
                        <DialogContent className={classes.contentSection}>
                            <div>
                                {t('ChooseNewDefaultWorkspaceDescription', {
                                    0: 'mockedDefaultWorkspaceName',
                                })}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                data-test-id="settings-button"
                                appearance="primary"
                                aria-label={t('GoToSettingsButton')}
                                onClick={() => setSettingsOpen(true)}
                                {...restoreFocusTargetAttribute}
                            >
                                {t('GoToSettingsButton')}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <UserSettingsModal
                data-testid="SettingsButton"
                open={settingsOpen}
                setOpen={setSettingsOpen}
                setMenuOpen={setOpen}
                {...restoreFocusSourceAttribute}
            />
        </>
    );
}
