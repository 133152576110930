import {IVerticalStackedChartProps} from '@fluentui/react-charting';
import {tokens} from '@fluentui/react-components';
import {
    CAPACITY_BUCKETS,
    CAPACITY_THRESHOLD_PERCENTAGE,
    DATETIME_FORMAT,
    MILLI_SECONDS_IN_HOUR,
    UNIT_CAPACITY_STATUS,
    UNIT_PLURAL_TEXT,
} from './UsageDashboard.constants';
import {HourlyCapacityUsageStatus} from './UsageDashboard.types';
import {HourlyCapacityUsage} from '@/api/capacities/capacities.types';
import {formatLocaleDate as format} from '@/util/locale';
import {addHours} from 'date-fns';
import {TFunction} from 'i18next';
import {hasStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import MedeinaFeatures, {MedeinaUrlParamFeatures} from '@/util/features';

export function transformDataForGraph(
    data: HourlyCapacityUsage[],
    translate: TFunction,
    version?: 'v1' | 'v2',
): IVerticalStackedChartProps[] {
    const usageCalculator = version === 'v2' ? calculateUsageForV2 : calculateUsage;
    return data.map((item) => {
        const usageData = usageCalculator(item);
        return createGraphItem(item, usageData, translate);
    });
}

export function transformDataForDataTable(
    data: HourlyCapacityUsage[],
): HourlyCapacityUsageStatus[] {
    return data?.map((item) => {
        const usageData = calculateUsage(item);
        return createDataTableItem(item, usageData.usageBucket);
    });
}

const getThresholdCapacity = (maxAllowedCapacity: number): number =>
    CAPACITY_THRESHOLD_PERCENTAGE * maxAllowedCapacity;

const calculateUsage = (item: HourlyCapacityUsage) => {
    const thresholdCapacity = roundToOneDecimal(getThresholdCapacity(item.maxAllowedCapacity));

    let usageBelowThreshold;
    let usageAboveThreshold;
    let usageThrottled;
    let usageBucket;

    // If usage within threshold
    if (item.usedCapacity < thresholdCapacity) {
        usageBelowThreshold = roundToOneDecimal(item.usedCapacity);
        usageAboveThreshold = 0;
        usageThrottled = 0;
        usageBucket = CAPACITY_BUCKETS.BELOW_THRESHOLD;
    }
    // If usage between threshold and max allowed capacity
    else if (
        item.usedCapacity >= thresholdCapacity &&
        item.usedCapacity < item.maxAllowedCapacity
    ) {
        usageBelowThreshold = thresholdCapacity;
        usageAboveThreshold = roundToOneDecimal(item.usedCapacity - thresholdCapacity);
        usageThrottled = 0;
        usageBucket = CAPACITY_BUCKETS.ABOVE_THRESHOLD;
    }
    // If usage exceeds max allowed capacity
    else {
        usageBelowThreshold = thresholdCapacity;
        usageAboveThreshold = roundToOneDecimal(item.maxAllowedCapacity - thresholdCapacity);
        usageThrottled = roundToOneDecimal(item.usedCapacity - item.maxAllowedCapacity);
        usageBucket = CAPACITY_BUCKETS.THROTTLED;
    }

    return {usageBelowThreshold, usageAboveThreshold, usageThrottled, usageBucket};
};

const calculateUsageForV2 = (item: HourlyCapacityUsage) => {
    const decimalPlacesToRound = getRoundOffDecimalCount();
    const thresholdCapacity = roundToDecimalPlaces(
        getThresholdCapacity(item.maxAllowedCapacity),
        decimalPlacesToRound,
    );
    const roundedUsedCapacity = roundToDecimalPlaces(item.usedCapacity, decimalPlacesToRound);
    const maxAllowedCapacity = item.maxAllowedCapacity;

    let usageBelowThreshold;
    let usageAboveThreshold;
    let usageThrottled;
    let usageBucket;

    // If usage within threshold
    if (roundedUsedCapacity < thresholdCapacity) {
        usageBelowThreshold = roundToDecimalPlaces(item.usedCapacity, decimalPlacesToRound);
        usageAboveThreshold = 0;
        usageThrottled = 0;
        usageBucket = CAPACITY_BUCKETS.BELOW_THRESHOLD;
    }
    // If usage between threshold and max allowed capacity
    else if (roundedUsedCapacity >= thresholdCapacity && roundedUsedCapacity < maxAllowedCapacity) {
        usageBelowThreshold = thresholdCapacity;
        usageAboveThreshold = roundToDecimalPlaces(
            roundedUsedCapacity - thresholdCapacity,
            decimalPlacesToRound,
        );
        usageThrottled = 0;
        usageBucket = CAPACITY_BUCKETS.ABOVE_THRESHOLD;
    }
    // If usage exceeds max allowed capacity
    else {
        usageBelowThreshold = thresholdCapacity;
        usageAboveThreshold = roundToDecimalPlaces(
            maxAllowedCapacity - thresholdCapacity,
            decimalPlacesToRound,
        );
        usageThrottled = roundToDecimalPlaces(
            roundedUsedCapacity - maxAllowedCapacity,
            decimalPlacesToRound,
        );
        usageBucket = CAPACITY_BUCKETS.THROTTLED;
    }

    return {usageBelowThreshold, usageAboveThreshold, usageThrottled, usageBucket};
};

// Graph data - representing each of three bars (teal, yellow, red)
const createGraphItem = (
    item: HourlyCapacityUsage,
    usageData: ReturnType<typeof calculateUsage>,
    translate: TFunction,
): IVerticalStackedChartProps => {
    const {usageBelowThreshold, usageAboveThreshold, usageThrottled, usageBucket} = usageData;

    // Convert input UTC date to user timezone date
    const zonedDate = new Date(getZonedDate(item.aggregateStartTime));
    const stackAccessibilityData = getStackAccessibilityData(
        usageData,
        zonedDate,
        item.assignedCapacity,
        translate,
    );

    return {
        chartData: [
            {
                legend: CAPACITY_BUCKETS.BELOW_THRESHOLD,
                data: usageBelowThreshold,
                color: tokens.colorBrandForegroundLink,
            },
            {
                legend: CAPACITY_BUCKETS.ABOVE_THRESHOLD,
                data: usageAboveThreshold,
                color: tokens.colorPalettePeachBorderActive,
            },
            {
                legend: CAPACITY_BUCKETS.THROTTLED,
                data: usageThrottled,
                color: tokens.colorStatusDangerBackground3,
            },
        ],
        xAxisPoint: zonedDate, // BarChart doesn't respect string for custom formatting
        xAxisCalloutData: usageBucket, // Using as a placeholder to show usage status on custom callout
        lineData: [
            {
                y: item.assignedCapacity,
                legend: CAPACITY_BUCKETS.ASSIGNED_CAPACITY,
                color: tokens.colorStrokeFocus2,
            },
        ],
        // Override is required as xAxisCalloutData, used as a placeholder for the custom hover card component, also gets read.
        // The usageBucket, being a key for translation text rather than suitable display text, needs to be replaced.
        stackCallOutAccessibilityData: {
            ariaLabel: stackAccessibilityData,
        },
    };
};

// Table data - representing each row and used as key to identify unit status amongst below, within or above threshold
const createDataTableItem = (
    item: HourlyCapacityUsage,
    usageBucket: string,
): HourlyCapacityUsageStatus => {
    // Convert input UTC date to user timezone date
    const zonedDate = new Date(getZonedDate(item.aggregateStartTime));
    const matchingStatus = Object.entries(UNIT_CAPACITY_STATUS).find(
        ([key, value]) => key === usageBucket,
    );

    return {
        startTime: zonedDate,
        usageValue: roundToOneDecimal(item.usedCapacity),
        usageStatus: {
            icon: (matchingStatus?.[1].icon as JSX.Element) ?? '',
            status: matchingStatus?.[1].status ?? '',
        },
    };
};

const getZonedDate = (date: string): string => {
    const zonedDate = format(date, DATETIME_FORMAT.ISO_STRING);

    return zonedDate;
};

export const getDayName = (date: Date): string => {
    return format(date, DATETIME_FORMAT.BARCHART_HOVERCARD_DAYNAME);
};

export const getHourlyTimeframe = (date: Date): string => {
    const formattedHour = format(date, DATETIME_FORMAT.BARCHART_HOVERCARD_TIMEFRAME);
    const nextHour = format(addHours(date, 1), DATETIME_FORMAT.BARCHART_HOVERCARD_TIMEFRAME);

    return `${formattedHour} - ${nextHour}`;
};

function getStackAccessibilityData(
    usageData: ReturnType<typeof calculateUsage>,
    date: Date,
    assignedCapacity: number,
    t: TFunction,
): string {
    const {usageBelowThreshold, usageAboveThreshold, usageThrottled, usageBucket} = usageData;

    const matchingStatus = Object.entries(UNIT_CAPACITY_STATUS).find(
        ([key, value]) => key === usageBucket,
    );

    const usageBucketText = `${t(matchingStatus?.[1].ariaLabel ?? '')}`;
    const usageBelowThresholdText = `${t(
        CAPACITY_BUCKETS.BELOW_THRESHOLD,
    )} ${usageBelowThreshold} ${t(UNIT_PLURAL_TEXT)}`;
    const usageAboveThresholdText = `${t(
        CAPACITY_BUCKETS.ABOVE_THRESHOLD,
    )} ${usageAboveThreshold} ${t(UNIT_PLURAL_TEXT)}`;
    const usageThrottledText = `${t(CAPACITY_BUCKETS.THROTTLED)} ${usageThrottled} ${t(
        UNIT_PLURAL_TEXT,
    )}`;
    const assignedCapacityText = `${t(CAPACITY_BUCKETS.ASSIGNED_CAPACITY)} ${assignedCapacity} ${t(
        UNIT_PLURAL_TEXT,
    )}`;

    const dayName = getDayName(date);
    const timeframe = getHourlyTimeframe(date);

    // Dots added to delay narrator.
    return `${usageBucketText}. ${dayName} from ${timeframe} . ${usageBelowThresholdText}. ${usageAboveThresholdText}. ${usageThrottledText}. ${assignedCapacityText}`;
}

// Number.Epsilon adds a small value to the number to avoid floating point arithmetic errors
export const roundToOneDecimal = (value: number): number =>
    Math.round((value + Number.EPSILON) * 10) / 10;

export const roundToDecimalPlaces = (value: number, decimalPlaces: number): number => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round((value + Number.EPSILON) * factor) / factor;
};

const convertDateToResponseFormat = (utcDateString: string): string => {
    const date = new Date(utcDateString);
    const utcString = date.toISOString().split('.')[0];
    return utcString;
};

export const fillBarGraphDataGaps = (
    data: HourlyCapacityUsage[],
    startTime: Date,
    endTime: Date,
): HourlyCapacityUsage[] => {
    const filledData: HourlyCapacityUsage[] = [];

    if (data.length > 0) {
        const dataStartDate = new Date(getZonedDate(data[0].aggregateStartTime));
        const dataEndDate = new Date(getZonedDate(data[data.length - 1].aggregateStartTime));
        const startDate = startTime;
        const endDate = endTime;
        const dataLength = data.length;

        // Calculate the difference in hours
        const diffInPreHours =
            (dataStartDate.getTime() - startDate.getTime()) / MILLI_SECONDS_IN_HOUR;

        // Fill the gap before the first data point
        for (let j = 1; j <= diffInPreHours; j++) {
            const gapTime = new Date(dataStartDate);
            gapTime.setHours(dataStartDate.getHours() - j);
            filledData.push({
                assignedCapacity: data[0].assignedCapacity,
                usedCapacity: 0,
                maxAllowedCapacity: data[0].maxAllowedCapacity,
                aggregateStartTime: convertDateToResponseFormat(gapTime.toISOString()),
            });
        }
        filledData.sort(
            (a, b) =>
                new Date(a.aggregateStartTime).getTime() - new Date(b.aggregateStartTime).getTime(),
        );

        for (let i = 0; i < dataLength - 1; i++) {
            filledData.push(data[i]);
            const currentTime = new Date(getZonedDate(data[i].aggregateStartTime));
            const nextTime = new Date(getZonedDate(data[i + 1].aggregateStartTime));

            // Calculate the difference in hours
            const diffInHours =
                (nextTime.getTime() - currentTime.getTime()) / MILLI_SECONDS_IN_HOUR;

            // If there's a gap of more than 1 hour, fill it
            if (diffInHours > 1) {
                for (let j = 1; j < diffInHours; j++) {
                    const gapTime = new Date(currentTime);
                    gapTime.setUTCHours(currentTime.getUTCHours() + j);
                    filledData.push({
                        assignedCapacity: data[i].assignedCapacity,
                        usedCapacity: 0,
                        maxAllowedCapacity: data[i].maxAllowedCapacity,
                        aggregateStartTime: convertDateToResponseFormat(gapTime.toISOString()),
                    });
                }
            }
        }

        filledData.push(data[dataLength - 1]);

        // Calculate the difference in hours
        const diffInPostHours = (endDate.getTime() - dataEndDate.getTime()) / MILLI_SECONDS_IN_HOUR;

        // If the gap from the last data point to the end time
        for (let j = 1; j <= diffInPostHours; j++) {
            const gapTime = new Date(dataEndDate);
            gapTime.setHours(dataEndDate.getHours() + j);
            filledData.push({
                assignedCapacity: data[dataLength - 1].assignedCapacity,
                usedCapacity: 0,
                maxAllowedCapacity: data[dataLength - 1].maxAllowedCapacity,
                aggregateStartTime: convertDateToResponseFormat(gapTime.toISOString()),
            });
        }
    }

    return filledData;
};

export const getRoundOffDecimalCount = (): number => {
    if (!MedeinaFeatures.EnableUsageMonitoringCustomRoundup) {
        return 1;
    }

    if (hasStaticFeatureFlag(MedeinaUrlParamFeatures.UsageMonitoringTruncate4)) {
        return 4;
    }
    if (hasStaticFeatureFlag(MedeinaUrlParamFeatures.UsageMonitoringTruncate1)) {
        return 1;
    }
    if (hasStaticFeatureFlag(MedeinaUrlParamFeatures.UsageMonitoringTruncate2)) {
        return 2;
    } else {
        return 3;
    }
};
